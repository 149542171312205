import React from "react"
import { Link } from "gatsby"

const PageSelector = ({ numPages, currentPage, urlPrefix }) => {
  const next = currentPage < numPages
  const previous = currentPage > 1
  return (
    <nav className="blog-post-nav">
      <div className="previous-post-nav">
        {previous && (
          <Link
            className="post-nav-link"
            to={urlPrefix + (currentPage > 2 ? currentPage - 1 : "")}
            rel="prev"
          >
            ← Anterior
          </Link>
        )}
      </div>
      <div className="right-post-nav">
        {next && (
          <Link
            className="post-nav-link"
            to={urlPrefix + (currentPage < numPages ? currentPage + 1 : "")}
            rel="next"
          >
            Próxima →
          </Link>
        )}
      </div>
    </nav>
  )
}

export default PageSelector
