import { Link } from "gatsby"
import React from "react"

import { convertNameToSlug } from "../utils/utils"

const Category = ({ catName, count, inline = true }) => {
  const catSlug = convertNameToSlug(catName)
  return (
    <span className={inline ? "category-in-line" : "category-badge"}>
      <Link to={"/category/" + catSlug}>
        {catName + (count ? ` (${count})` : "")}
      </Link>
    </span>
  )
}

export default Category
