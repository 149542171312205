import React from "react"
import { Link } from "gatsby"
import Category from "./category"
import { GatsbyImage } from "gatsby-plugin-image"

const PostItem = ({ post }) => {
  let featuredImgFluid = post.frontmatter.featuredImage
    ? post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : null
  let excerpt = post.frontmatter.excerpt || post.excerpt
  let categories = post.frontmatter.categories

  return (
    <article className="card">
      {categories ? (
        <div className="categories-wrapper">
          {categories
            .map(t => <Category key={t} catName={t} inline={false} />)
            .reduce((prev, curr) => [prev, ", ", curr])}
        </div>
      ) : null}
      {featuredImgFluid ? (
        <Link to={"/" + post.fields.slug} itemProp="url">
          <GatsbyImage
            image={featuredImgFluid}
            alt={post.frontmatter.featuredImageAlt}
          />
        </Link>
      ) : null}

      <Link to={"/" + post.fields.slug} itemProp="url">
        <h1>{post.frontmatter.title}</h1>
      </Link>
      {excerpt ? (
        <p
          dangerouslySetInnerHTML={{
            __html: excerpt,
          }}
        />
      ) : null}
      <footer>{post.frontmatter.date}</footer>
    </article>
  )
}

export default PostItem
