import React from "react"
import PostItem from "./post-item"

const PostList = ({ posts }) => {
  return (
    <div className="cards">
      {posts.map(({ node }) => (
        <PostItem key={node.fields.slug} post={node} />
      ))}
    </div>
  )
}

export default PostList
